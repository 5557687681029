import request from '@/utils/request'

export function getSupplierUnpaids(params) {
  return request({
    method: 'get',
    url: 'supplier-unpaid',
    params
  })
}

export function payForSupplierUnpaid(id, data) {
  return request({
    method: 'post',
    url: `supplier-unpaid/${id}/payment`,
    data
  })
}

export function getSupplierPayments(id, params) {
  return request({
    method: 'get',
    url: `supplier-unpaid/${id}/detail`,
    params
  })
}

export function uploadReceipt(file) {
  const form = new FormData()
  form.append('image', file)
  return request({
    method: 'post',
    url: 'supplier-unpaid/upload',
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export function payment(data) {
  return request({
    method: 'post',
    url: `supplier-unpaid/payment`,
    data
  })
}

export function getSuppliers(params) {
  return request({
    method: 'get',
    url: `supplier-unpaid/supplier`,
    params
  })
}

export function addSupplierIou(data) {
  return request({
    method: 'post',
    url: `supplier-unpaid/iou`,
    data
  })
}

export function getSupplierIou(params) {
  return request({
    method: 'get',
    url: `supplier-unpaid/iou`,
    params
  })
}

export function paymentSupplierIou(data) {
  return request({
    method: 'post',
    url: `supplier-unpaid/iou/payment`,
    data
  })
}

export function getSupplierIouPayment(params) {
  return request({
    method: 'get',
    url: `supplier-unpaid/iou/payment`,
    params
  })
}
