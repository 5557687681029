<template>
  <div>
    <my-nav-bar
      title="欠条明细"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="getList"
      >
        <div v-if="list.length > 0" class="tips">提示：往左滑动付款</div>
        <van-swipe-cell
          v-for="supplierIou in list"
          :key="supplierIou.id"
        >
          <van-cell
            :title="'欠条日期：' + supplierIou.iou_date"
            :value="'剩余：￥' + supplierIou.surplus_amount"
            :label="'操作员：' + supplierIou.employee.name"
            value-class="cell-value"
            is-link
            center
            @click="toIouPaymentView(supplierIou)"
          />
          <template #right>
            <van-button style="height: 100%" square type="primary" @click="handlePayment(supplierIou)">付款</van-button>
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
    <my-dialog v-model="showDialog" title="欠条付款" @confirm="payment">
      <div class="payment-container">
        <van-cell title="付款方式" value-class="pay-mode-value" center>
          <van-radio-group v-model="paymentData.payment_mode" direction="horizontal">
            <van-radio icon-size="16px" :name="1">现金</van-radio>
            <van-radio icon-size="16px" :name="2">微信</van-radio>
            <van-radio icon-size="16px" :name="3">转账</van-radio>
          </van-radio-group>
        </van-cell>
        <van-cell title="付款日期" :value="paymentData.payment_date" is-link center @click="showDatePicker = true" />
        <van-field v-model="paymentData.payment_amount" label="付款金额" placeholder="请输入付款金额" input-align="right" type="number" />
      </div>
    </my-dialog>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="handleSelectDate"
        @cancel="showDatePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getSupplierIou, paymentSupplierIou } from '@/api/supplier-unpaid'
import { toDateString } from '@/utils'
import MyDialog from '@/components/my-dialog.vue'
export default {
  name: 'SupplierIou',
  components: { myNavBar, MyDialog },
  data() {
    MyDialog
    return {
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        page: 0,
        limit: 20,
        supplier_id: this.$route.query.supplier_id
      },
      showEmpty: false,
      paymentData: {
        payment_amount: '',
        payment_date: ''
      },
      currentDate: new Date(),
      showDialog: false,
      showDatePicker: false
    }
  },
  created() {
    this.$store.commit('setKeepAlive', this.$options.name)
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path === '/supplier-unpaid') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getSupplierIou(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    reload() {
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    },
    handlePayment(supplierIou) {
      this.paymentData = {
        payment_amount: '',
        payment_date: '',
        iou_id: supplierIou.id,
        payment_mode: 2
      }
      this.showDialog = true
    },
    handleSelectDate(date) {
      this.paymentData.payment_date = toDateString(date)
      this.showDatePicker = false
    },
    payment() {
      this.beginLoad()
      paymentSupplierIou(this.paymentData).then(res => {
        this.success(res.msg)
        this.reload()
      })
    },
    toIouPaymentView(supplierIou) {
      this.$router.push({
        path: '/supplier-unpaid-payment',
        query: {
          iou_id: supplierIou.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .cell-value {
    color: $amountRedColor;
  }
  .tips {
    padding: 10px;
    color: $grayTextColor;
  }
  .payment-container {
    padding: 10px 0;
  }
  .pay-mode-value {
    flex: 2;
  }
</style>
